import Layout from '@/layout/index';
export default {
  path: '/orderManage',
  name: 'orderManage',
  component: Layout,
  redirect: '/orderManage/orderList',
  meta: {
    title: '订单管理',
  },
  children: [
    {
      path: 'orderList',
      name: 'orderList',
      component: () => import('@/views/orderManage/orderList'),
    },
    {
      path: '/newAddOrder',
      name: 'newAddOrder',
      component: () => import('@/views/orderManage/newAddOrder'),
      meta: {
        title: '订单表单',
        keepAlive: true,
        goBack: true,
      },
    },
    {
      path: '/contentWatchList',
      name: 'contentWatchList',
      redirect: '/contentWatchList',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        title: '监测概况',
        keepAlive: true,
        goBack: true,
      },
      children: [
        {
          path: '/contentWatchList',
          name: 'contentWatchList',
          component: () => import('@/views/surveyData/contentWatch'),
        },
        {
          path: '/contentWatchList/orderCardList',
          name: 'orderCardList',
          component: () => import('@/views/surveyData/orderCardList'),
          meta: {
            title: '指标详情',
            keepAlive: true,
            goBack: true,
          },
        },
        {
          path: '/clickArtificial',
          redirect: '/clickArtificial',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          meta: {
            title: '待人工监测',
            keepAlive: true,
            goBack: true,
          },
          children: [
            {
              path: '/clickArtificial',
              component: () => import('@/views/surveyData/contentWatch'),
            },
            {
              path: '/clickArtificial/orderCardList',
              name: 'orderCardList',
              component: () => import('@/views/surveyData/orderCardList'),
              meta: {
                title: '人工指标详情',
                keepAlive: true,
                goBack: true,
              },
            },
          ],
        },
      ],
    },
  ],
};
