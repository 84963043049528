
import { removeToken } from '@/utils/auth';

let dictMap = {};
getMap();
async function getMap() {
  let _res = JSON.parse(window.sessionStorage.getItem('dictList'));
  removeToken();
  if(_res){
    _res.some((item) => {
      if (item.dictParentValue == null || item.dictParentValue === '') {
        dictMap[item.dictCode] = {
          label: item.dictName,
          code: item.dictCode,
          children: [],
        };
      }
    });
    _res.some((item) => {
      if (item.dictParentValue && dictMap[item.dictParentValue]) {
        dictMap[item.dictParentValue].children.push({
          id: item.dictUuid,
          label: item.dictKey,
          value: item.dictValue,
        });
      }
    });
  }
}

export function getLabel(code, value) {
  let _arr = dictMap[code]?.children || [];
  let _res = _arr.filter((item) => item.value + '' === value + '');
  return _res[0]?.label || '';
}

function getList(code) {
  if (dictMap[code]) {
    return dictMap[code].children || [];
  } else {
    return [];
  }
}

const install = function (Vue) {
  Vue.prototype.$dict = {
    getLabel,
    getList,
  };
};

export default install;
