import request from '@/utils/require';
import { fd } from '@/utils/public';
const baseUrl = '/site-monitor-service';

export default {
  /************* 运营客服管理 *************/
  // 运营管理-订单分配运营列表查询
  operationFindAgencyUser(option) {
    return request.post(`${baseUrl}/order/operationFindAgencyUser`, fd(option));
  },
  operationFindAllOperationUser() {
    return request.get(`${baseUrl}/orderUser/operationFindAllOperationUser`);
  },
  // 订单列表
  getOrderList(option) {
    return request.get(
      `${baseUrl}/order/pageList/${option.pageNum}/${option.pageSize}?orderCode=${option.orderCode}&agencyName=${option.agencyName}&staffIdList=${option.staffIdList}&orderStatus=${option.orderStatus}&assignStatus=${option.assignStatus}`,
      option
    );
  },
  // 根据用户获取机构
  getTreeList() {
    return request.get(`${baseUrl}/system/agency/tree`);
  },
  // 获取栏目
  getTableData(option) {
    return request.get(`${baseUrl}/grabChannel/list`, { params: option });
  },
  // 新增栏目
  addGrabChannel(option) {
    return request.post(`${baseUrl}/grabChannel/add`, option);
  },
  //删除栏目
  deleteGrabChannel(option) {
    return request.delete(`${baseUrl}/grabChannel/remove/${option.ids}`);
  },
  // 修改栏目
  editGrabChannel(option) {
    return request.post(`${baseUrl}/grabChannel/edit`, option);
  },
  // 获取订单指标详情
  getOrderDetail(option) {
    return request.get(`${baseUrl}/order/orderDetail`, { params: option });
  },
  createOrder(option) {
    return request.post(`${baseUrl}/order/createOrder`, option);
  },
  // 任务获取运营人员
  getUserList(option) {
    return request.post(
      `${baseUrl}/system/user/pageList?roleKeys=${option.roleKeys}`,
      option
    );
  },
  //批量新增运营订单关系
  addDistribution(option) {
    return request.post(`${baseUrl}/order/assignOrder`, option);
  },

  // 更新订单状态
  updateOrderStatus(option) {
    return request.post(`${baseUrl}/order/updateOrderStatus`, option);
  },
  //删除
  deleteOrder(option) {
    return request.delete(`${baseUrl}/order/delete/${option.ids}`);
  },
  // 网站管理列表
  getSitemanagementList(option) {
    return request.get(
      `${baseUrl}/sitemanagement/pageList?pageNum=${option.pageNum}&pageSize=${option.pageSize}&agencyId=${option.agencyId}&agencyName=${option.agencyName}`,
      option
    );
  },
  // 获取栏目列表
  getGrabChannelList(option) {
    return request.get(`${baseUrl}/grabChannel/list`, { params: option });
  },
  // 获取栏目类型
  getColumns(option) {
    return request.post(`${baseUrl}/system/dictionary/findByName`, option);
  },
  // 网站配置-创建
  createSiteconfig(option) {
    return request.post(`${baseUrl}/siteconfig/create`, option);
  },
  // 获取网站配置
  getSiteconfigList(option) {
    return request.get(`${baseUrl}/siteconfig/pageList`, { params: option });
  },
  // 网站配置-更新
  updateSiteconfig(option) {
    return request.post(`${baseUrl}/siteconfig/update`, option);
  },
};
