import request from '@/utils/require';
const baseUrl = '/site-spider-service';
export default {
  /************* 采集任务 *************/
  // 根据当前用户获取行业任务IDs
  getTaskIdsByCurrentUser(option) {
    return request.post(`${baseUrl}/order/getTaskIdsByCurrentUser`, option);
  },
  // 查询数据采集任务
  queryTaskList(option) {
    return request.get(`${baseUrl}/task/queryPageList`, { params: option });
  },
  // 新建采集任务
  addCorrectWord(option) {
    return request.post(`${baseUrl}/task/create`, option);
  },
  // 修改采集任务
  editCorrectWord(option) {
    return request.post(`${baseUrl}/task/edit`, option);
  },
  // 查看数据采集任务
  queryById(option) {
    return request.get(`${baseUrl}/task/queryById`, { params: option });
  },
  // 撤销数据采集任务
  delById(option) {
    return request.get(`${baseUrl}/task/cancelTask`, { params: option });
  },
  // 拒绝采集任务
  rejectById(option) {
    return request.get(`${baseUrl}/task/rejectTask`, { params: option });
  },
  // 查询模板---post拼接url形式传参
  queryModelPageList(option) {
    return request.post(
      `${baseUrl}/model/queryPageList`,
      {},
      { params: option }
    );
  },
  // 新增模板
  addModel(option) {
    return request.post(`${baseUrl}/model/add`, option);
  },
  // 新增模板
  editModel(option) {
    return request.post(`${baseUrl}/model/edit`, option);
  },
  // 绑定模板
  channelBindModel(option) {
    return request.get(`${baseUrl}/task/channelBindModel`, { params: option });
  },
  // 查询模板---post拼接url形式传参
  crawlingAll(option) {
    return request.post(`${baseUrl}/task/crawling/all`, {}, { params: option });
  },
  delModel(option) {
    return request.post(`${baseUrl}/model/del`, {}, { params: option });
  },
  // 撤销数据采集任务
  getAbnormalList(option) {
    return request.get(`${baseUrl}/task/abnormalList`, { params: option });
  },
};
