<template>
  <router-link class="link" :to="data.url">
    <el-menu-item :key="data.menuId" :index="data.url">
      <menu-title v-bind="data" />
    </el-menu-item>
  </router-link>
</template>

<script>
import MenuTitle from './menu-title.vue';
export default {
  name: 'MenuItem',
  components: { MenuTitle },
  props: {
    data: Object,
    label: Number,
    isCollapse: Boolean,
  },
};
</script>

<style scoped lang="scss">
.link {
  color: #fff;
  text-decoration: none;
  display: inline;
  width: 100%;
  height: 100%;
}
</style>
