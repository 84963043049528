import request from '@/utils/require';
import { fd } from '@/utils/public';
const baseUrl = '/site-monitor-service';

export default {
  //  登录
  login(option) {
    return request.post(`${baseUrl}/login`, option);
  },

  sendVerifyCode(query) {
    return request.get(`${baseUrl}/sendVerifyCode?phone=${query}`);
  },
};
