import Layout from '@/layout/index';
export default {
  path: '/personalCenter',
  name: 'personalCenter',
  component: Layout,
  meta: {
    title: '个人中心',
  },
  children: [
    {
      path: 'personalCenterMainView',
      name: 'personalCenterMainView',
      component: () => import('@/views/personalCenter/personalCenterMainView'),
      meta: {
        title: '个人中心',
        keepAlive: true,
        goBack: true,
      },
    },
  ],
};

