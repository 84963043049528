<template>
  <div class="user-operate">
    <div class="user-msg">
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-user"></i
          >{{ userInfo.userName ? userInfo.userName : '用户'
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleClick('1')"
            ><i class="el-icon-price-tag"></i>个人中心</el-dropdown-item
          >
          <el-dropdown-item @click.native="handleClick('2')"
            ><i class="el-icon-message"></i>意见反馈</el-dropdown-item
          >
          <el-dropdown-item @click.native="handleClick('3')"
            ><i class="el-icon-switch-button"></i>退出</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
      append-to-body
      title="意见反馈"
      :visible.sync="dialogVisible"
      :with-header="true"
      class="feed-back-content"
    >
      <!--      <el-divider class="divider-style"></el-divider>-->
      <div class="feedback-form">
        <el-input
          class="feedback-input"
          type="textarea"
          maxlength="1000"
          show-word-limit
          :rows="rowsNumber"
          placeholder="请告诉我们您的宝贵意见，我们会持续改进，共同见证我们的成长吧"
          v-model="textarea"
        >
        </el-input>
        <div class="sendBox">
          <el-button class="send-btn" type="primary" round @click="sendFeedBack"
            >发送</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import api from '@/api/module/user';

export default {
  name: 'APPUser',
  data() {
    return {
      textarea: '',
      rowsNumber: 10,
      userInfo: {},
      dialogVisible: false,
      showFeedBackDrawerStatus: false,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    let currentHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.rowsNumber = Math.floor(currentHeight / 50);
  },
  methods: {
    logout() {
      this.$msg.confirm('确认退出').then(() => {
        api.logoutUser().then((res) => {
          if (res.code !== 500) {
            let loginPath = '';
            let userInfo = JSON.parse(
              window.sessionStorage.getItem('userInfo')
            );
            loginPath = userInfo.fullPath;
            window.sessionStorage.removeItem('userInfo');
            window.sessionStorage.removeItem('orderCode');
            // 退出成功后关闭所有tag
            this.$store.dispatch('tagsView/delAllViews');
            this.$router.push({ path: loginPath });
          }
        });
      });
    },
    handleClick(command) {
      // 个人中心
      if (command === '1') {
        this.$router.push({
          path: '/personalCenter/personalCenterMainView',
        });
      }

      // 意见反馈
      if (command === '2') {
        this.showFeedBackDrawerStatus = true;
        this.dialogVisible = true;
        this.textarea = '';
      }

      // 退出
      if (command === '3') {
        this.logout();
      }
    },
    sendFeedBack() {
      if (!this.textarea) {
        this.$message({ message: '请填写反馈信息', type: 'error' });
        return;
      }

      this.$api.personalCenter
        .sendFeedBack({ feedbackData: this.textarea })
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: '发送成功', type: 'success' });
            this.dialogVisible = false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.feedback-input {


  .el-input__count {
    padding-right: 20px;
  }
}
.feed-back-content {
  .el-drawer.rtl {
    width: 50% !important;
  }
}
</style>

<style scoped lang="scss">
.user-operate {
  display: flex;
  align-items: center;
  color: #333333;

  &::before {
    position: absolute;
    left: 0px;
    content: '';
    height: 100%;
    width: 102px;
    -webkit-background-size: 100%;
    background-size: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    left: 101px;
    top: 0px;
    right: 0px;
    // background: #ffffff;
    // border-top: 1px solid #35588c;
  }

  > div {
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  position: absolute;
  right: 0px;
  font-size: 0px;

  .user-msg {
    padding: 0 16px 0 0;
    font-size: 14px;
    color: #333333;

    i {
      margin-right: 4px;
    }
  }

  .quit {
    font-size: 14px;
    width: 73px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 32px;
    // background: #2281ff;
    margin-right: 20px;
    // color: #ffffff;
    cursor: pointer;
    font-weight: bold;

    i {
      font-weight: bold;
    }
  }
}
.feed-back-content {
  height: 60%;
}

.feedback-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: end;
}

.feedback-input {
  padding: 0 20px;
}

.sendBox {
  flex: 1;
  display: flex;
  align-items: center;
}

.send-btn {
  width: 60px;
  margin-right: 10px;
}

.divider-style {
  margin-top: 1px;
}
.el-dropdown{
  // color: #fff;
}
</style>
