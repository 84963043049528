import Vue from 'vue';
import VueRouter from 'vue-router';
import project from './module/project';

import orderManage from './module/orderManage';

import personalCenter from './module/personalCenter';
import sysManage from './module/sysManage';
import dataAcquisition from './module/dataAcquisition';
import dataCollector from './module/dataCollector';
import webManage from './module/webManage';



Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: '/',
    // name: 'Home',
    component: () => import('@/views/login/index'),
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/login/:institution',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  personalCenter,
  project,
  sysManage,
  orderManage,
  dataAcquisition,
  dataCollector,
  webManage
];
const router = new VueRouter({
  base: '/content/',
  routes,
});

// 递归遍历后端返回的menu数据来meta中title的数据
function updateTitle(menu, to) {
  let list = [];
  menu.forEach((item) => {
    if (item.children && item.children.length) {
      updateTitle(item.children, to);
    } else {
      if (to.path === item.url) {
        to.meta.title = item.menuName;
        return;
      }
    }
  });
  return list;
}

router.beforeEach((to, from, next) => {
  let titleName
  // if (window.location.host.replace("www.", "")==='zgsjbs.cangjiejd.com') {
  //   titleName ="中国审计报社"
  // }else if(window.location.host.replace("www.", "")==='cttp.cangjiejd.com'){
  //   titleName ="中国旅游出版社"
  // }else{
    titleName='监测平台'
  // }
  window.document.title = titleName
    let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    // 登录页面
    if (to.path === '/login' || to.path.match(/login/g)) {
      // 登录过期
      if (to.params && to.params.loginExpired) {
        window.sessionStorage.removeItem('userInfo');
        window.sessionStorage.removeItem('orderCode');
        next();
      }
      // 用户已登录
      if (userInfo) {
        next({ path: userInfo.menu[0].url });
      } else {
        // 用户未登录
        next();
      }
    } else if (userInfo) {
      updateTitle(userInfo.menu, to);
      if (to.query.metaTitle) {
        to.meta.title = to.query.metaTitle;
      }
      next();
    } else {
      // 非login，用户未登录
      next({ path: '/login' });
  }
});

// router.beforeEach((to, from, next) => {
//   // 目标路由keepAlive，则存入store
//   if (to.meta.keepAlive) {
//     store.commit('app/RESET_KEEPALIVE');
//     store.commit('app/SET_KEEPALIVE', to.name);
//   }
//   if (to.meta.isParent === true || to.meta.restParent) {
//     to.meta.parent = [];
//   } else if (!to.meta.unEntend && from.meta.isBack !== true) {
//     if (from.meta.parent) {
//       // 如果将要去的路由存在 to.meta.parent 中 说明是点击面包屑跳转 做处理
//       let ind = to.meta.parent ? to.meta.parent.findIndex(item => item.fullPath === to.fullPath) : null;
//       if (!ind) {
//         to.meta.parent = [...from.meta.parent, from];
//       }
//     } else {
//       to.meta.parent = [from];
//     }
//   }
//   next();
// });

export default router;
