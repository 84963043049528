const confirm = function (option) {
  let _default = {
    message: '',
    title: '提示',
    option: {
      type: 'warning',
      closeOnClickModal: false,
    },
  };
  if (typeof option === 'string') {
    _default.message = option;
  } else {
    _default = { ..._default, ...option };
  }
  return this.$confirm(_default.message, _default.title, _default.option);
};
const alert = function (option) {
  let _default = {
    message: '',
    title: '提示',
    option: {
      closeOnClickModal: false,
    },
  };
  if (typeof option === 'string') {
    _default.message = option;
  } else {
    _default = { ..._default, ...option };
  }
  return this.$alert(_default.message, _default.title, _default.option);
};

const install = function (Vue) {
  if (install.installed) {
    return null;
  }
  install.installed = true;
  Object.defineProperties(Vue.prototype, {
    $msg: {
      get() {
        return {
          confirm: (option) => confirm.call(this, option),
          alert: (option) => alert.call(this, option),
        };
      },
    },
  });
};

export default install;
