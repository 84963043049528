import { setToken, getToken, removeToken } from './utils/auth';
import { getParam } from './utils/utils';

const sso_code = getParam('sso_code');
if (sso_code) {
  setToken(sso_code);
}
// if (!getToken('yn_user_id')) {
//   removeToken('sso_token');
//   removeToken('yn_user_id');
//   window.location.href = '/redirect.html';
// }
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import bus from './utils/bus';

import api from '@/api/index';

import ElementUI from 'element-ui';
import './styles/element-variables.scss';

import '@/utils/rem';
import '@/styles/index.scss';
import '@/styles/table-msg.scss';
import '@/icons'; // icon
import './iconfont/iconfont.css';

//省市区联动
import regionSelect from './components/RegionSelect/Index';
// 数据表格
import pageTable from './components/Table/Index';
// 查询组件
import tableSearch from './components/TableSearch';
// 表单操作按钮框
import formBtn from './components/formBtn';
// 公共弹框
import messageBox from './utils/messageBox';
// 数据字典
import config from './config/globalConfig';

import dictPlugin from '@/config/dict';

// echarts
import * as echarts from 'echarts';
import 'echarts-liquidfill'

Vue.use(dictPlugin);

Vue.component('pageTable', pageTable);
Vue.component('tableSearch', tableSearch);
Vue.component('formBtn', formBtn);
Vue.component('regionSelect', regionSelect);

Vue.prototype.$bus = bus;

Vue.prototype.$api = api;

Vue.prototype.$config = config;

Vue.prototype.$echarts = echarts;

// Vue.prototype.$msg = messageBox;
Vue.use(messageBox);

Vue.config.productionTip = false;

Vue.use(ElementUI, {
  size: 'small',
});

// loading 加载框
Vue.prototype.openLoading = function () {
  const loading = this.$loading({
    lock: true,
    text: '正在处理中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#ffffff',
    target: '.sub-main',
    body: true,
    customClass: 'mask',
  });
  // loading.close()
  return loading;
};

if (module.hot) {
  module.hot.accept();
}

const $ = require('jquery');
window.$ = $;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
