// 设置 rem 函数
(function () {
  function setRem() {
    // 1920 默认大小16px;每个元素px基础上/16
    const htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
       document.documentElement.style.fontSize = (htmlWidth * 16) / 1440 + 'px';
  }
  // 初始化
  setRem();
  window.addEventListener('resize', setRem, false);
})();
