<template>
  <div class="com-data-table">
    <slot name="table" :data="tableData" :loading="loading">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        style="width: 100%"
        stripe
        border
        empty-text="暂无数据"
        tooltipEffect="light"
        :class="{ multipleHeader: multipleHeader }"
        :data="tableData"
        :row-key="rowKey"
        :height="height"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ background: '#f1f1f1' }"
        :show-summary="showSummary"
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        @select="select"
        @select-all="selectAll"
        @row-click="rowClick"
        @row-dblclick="rowDblclick"
        @cell-dblclick="cellBbClick"
        @cell-click="cellClick"
        @expand-change="expandChange"
      >
        <template v-if="columns && columns.length > 0">
          <template v-for="(item, index) in columns">
            <TableColumnItem
              v-if="!item.operate"
              :key="item.key || item.prop || index"
              :column="item"
            >
              <template :slot="item.prop" slot-scope="scope">
                <slot :name="item.prop" v-bind="scope" />
              </template>
            </TableColumnItem>
            <!-- 多表头组件加操作按钮 -->
            <el-table-column
              v-else
              :key="item.dataIndex || 'operate'"
              :label="item.label || '操作'"
              :header-align="item.headerAlign"
              :fixed="item.fixed"
              :align="item.align"
              :width="item.width"
              :min-width="item.minWidth"
            >
              <template slot-scope="scope">
                <div class="data-operate">
                  <span
                    v-for="(v, i) in item.operate"
                    :key="i"
                    class="op-edit"
                    :class="v.className"
                    :disabled="v.disabled"
                    @click="$_buttonMethods(v.func, index, scope.row)"
                    >{{ v.name }}</span
                  >
                </div>
              </template>
            </el-table-column>
          </template>
        </template>
        <slot v-else />
        <!-- <template slot="empty">
        <table-data-empty is-table-center />
      </template> -->
      </el-table>
    </slot>
    <div
      v-show="total"
      v-if="!hiddenPage"
      class="com-table-page"
      style="text-align: right"
    >
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        prev-text="上一页"
        next-text="下一页"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
/**
 * @callback CBgetPageDataOfAction
 * 通过不同的操作获取分页数据
 * - 方便页面调用
 * @param {'search'|'add'|'del'|'edit'} action
 * - search 也可用重置等操作
 * - add 含义为总列表数量增多
 * - del 含义为当前列表数量减少
 * - edit 含义为服务器更新当前行数据
 * @param {number|any} otherParams
 * - 如果是 action=del, 为 number=删除的数量
 */

import TableColumnItem from '@/components/Table/TableColumnItem.vue';
import { calcDomHeight } from '@/utils/public';
// import TableDataEmpty from '@/components/DataEmpty.vue';
export default {
  name: 'PageTable',
  components: {
    TableColumnItem,
    // TableDataEmpty,
  },
  props: {
    // 是否为多级表头
    multipleHeader: {
      type: Boolean,
      default: false,
    },
    rowKey: { type: String, default: 'id' },
    // 表头列表
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 是否初始化的时候不加载
    initFalse: {
      type: Boolean,
      default: false,
    },
    // 请求数据的接口
    apiFunction: {
      type: Function,
      default: () => {},
    },
    method: {
      type: String,
      default: 'get',
    },
    // 查询参数
    params: {
      // 数据
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否显示分页
    hiddenPage: {
      type: Boolean,
      default: false,
    },
    pageSizes: {
      // 分页规则
      type: Array,
      default: () => {
        // return [1, 2, 3, 10, 20, 50, 100, 200];
        return [10, 20, 50, 100, 200];
      },
    },
    height: {
      type: [String, Number],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    /**
     * 是否启用父组件数据
     *
     * 需传 externalData
     */
    enableExternalData: {
      type: Boolean,
      default: false,
    },
    // 父组件传过来的tableData
    externalData: {
      type: Array,
      default: () => [],
    },
    externalDataTotal: {
      type: Number,
      default: 0,
    },
    // 数据过滤标志
    filterCode: {
      type: String,
      default: '',
    },
    /*  pageNum: {
            type   : Number,
            default: 1 // 20
        }, */
    // 表格最大高度
    maxHeight: {
      type: [String, Number],
      default: () => {
        let _screen = window.innerWidth;
        if (_screen >= 1920) {
          return '600';
        } else if (_screen >= 1600) {
          return '500';
        } else if (_screen >= 1440) {
          return '500';
        } else if (_screen >= 1366) {
          return '440';
        }
        // return '500';
      },
    },
    // 是否合计
    showSummary: {
      type: Boolean,
      default: false,
    },
    // 自定义合计
    getSummaries: {
      type: Function,
      default: () => {},
    },
    processList: { type: Function, default: (list) => list },
  },
  data() {
    return {
      tableMaxHeight: this.maxHeight,
      // 获取到的数据
      tableData: [],
      // 当前页数
      currentPage: 1,
      // 共计0
      total: 0,
      layout: 'total, prev, pager, next',

      // 是否显示加载中
      loading: false,
      // 多选的数据 当前选中数据
      multipleSelection: [],
      // 单选的数据
      selectionData: {},
    };
  },
  watch: {
    externalData: {
      handler: function (val) {
        this.$set(this, 'tableData', val);
        this.total = this.externalDataTotal;
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.initFalse) {
      if (this.enableExternalData) {
        this.total = this.externalDataTotal;
        this.tableData = this.externalData;
        return;
      }
      this.getPageData();
    }
  },
  methods: {
    resize: function () {
      this.$refs.multipleTable?.doLayout();
    },

    // 计算表格最大高度
    // doLayout() {
    //   this.$nextTick(() => {
    //     const parentSelects = '.pt-wrapper';
    //     this.tableMaxHeight =
    //       calcDomHeight(
    //         [`${parentSelects}`],
    //         [
    //           `${parentSelects} .search-wrap`,
    //           `${parentSelects} .table-opt`,
    //           `${parentSelects} .com-table-page`,
    //         ],
    //         0
    //       ) + '';
    //   });
    // },
    getRowKey(row) {
      return row.id || row.uuid;
    },
    /**
     * 获得分页后的数据
     * @param {'reload'|'refresh'} action
     */
    getPageData(action = 'reload') {
      if (action === 'refresh') {
        // 刷新当前页：如 编辑 操作
      } else if (action === 'reload') {
        // search reset
        this.currentPage = 1;
      }
      this.reloadData(this.params);
    },
    /**
     * 删除后，更新页面数据
     * @param {number} num 删除了几条
     */
    getPageDataBeforeDel(num = 1) {
      const curPage = this.currentPage;
      const total = this.total;
      const pageSize = this.pageSize;
      const pages = Math.ceil(total / pageSize);
      const lastPageNum = total % pageSize;
      if (curPage === pages) {
        // 最后一页
        if (num === lastPageNum) {
          this.currentPage = Math.ceil(curPage - 1);
        } else if (num > lastPageNum) {
          // 动态监测 -内容校对管理-同类一键确认会遇到
          const pagesInit = Math.ceil((num - lastPageNum) / pageSize);
          const curP = curPage - pagesInit;
          this.currentPage = Math.ceil(curP || 1);
        } else {
          // 刷新当前页
        }
      } else {
        // 刷新当前页
      }
      this.reloadData(this.params);
    },
    /**
     * 新增后，更新页面数据
     * @param {'last'|'first'} viewNew 跳转到新数据页
     * - last 往列表尾添加数据
     * - first 往列表前添加数据
     */
    getPageDataBeforeAdd(viewNew = 'last') {
      // 目前只考虑新增一条
      // const curPage = this.currentPage;
      const total = this.total;
      const pageSize = this.pageSize;
      // const pages = Math.ceil(total / pageSize);
      // const lastPageNum = total % pageSize;

      if (viewNew === 'first') {
        this.currentPage = 1;
      } else {
        this.currentPage = Math.ceil((total + 1) / pageSize);
        this.total = total + 1;
      }

      this.reloadData(this.params);
    },
    /** @type {CBgetPageDataOfAction} */
    getPageDataOfAction(action, otherParams) {
      if (action === 'search') {
        this.getPageData('reload');
      } else if (action === 'add') {
        this.getPageDataBeforeAdd('last');
      } else if (action === 'edit') {
        this.getPageData('refresh');
      } else if (action === 'del') {
        this.getPageDataBeforeDel(otherParams || 1);
      } else {
        this.getPageData('reload');
      }
    },
    getPageDataParams() {
      // this.currentPage = 1
      this.reloadData(this.params);
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 参数查询
    reloadData(params) {
      this.loading = true;
      let locationOrigin = window.location.origin;
      if (!this.hiddenPage) {
        this.apiFunction({
          ...params,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        }).then((res) => {
          if (res && res.data) {
            const tableData = res.data.records
              ? res.data.records
              : res.data.list;

            this.tableData = this.processList(tableData);
            // 处理指标详情
            if (params.special && params.special === 'orderCardList') {
              if (this.tableData.length !== 0) {
                this.tableData.forEach((obj) => {
                  //extInfo 处理
                  if (obj.extInfo) {
                    obj.extInfo = JSON.parse(obj.extInfo);
                  }
                  // 图片处理
                  if (obj.manualImg && obj.manualImg !== '') {
                    obj.manualImg = obj.manualImg.split(',');
                    obj.manualImg.forEach((item1, index) => {
                      obj.manualImg[index] = `${
                        locationOrigin + '/site-monitor-service' + item1
                      }`;
                    });
                  } else if (obj.manualImg) {
                    obj.manualImg = [];
                  }
                });
              }
            }
            this.total = parseInt(res.data.total);
            this.loading = false;
            this.$emit('callback', this.tableData);
          }

          this.loading = false;
          // this.doLayout();
        });
      } else {
        this.apiFunction(params).then((res) => {
          let tableData = [];
          if (this.filterCode) {
            tableData = res.data.filter((ele) => {
              return ele.areaCode === this.filterCode;
            });
          } else {
            tableData = res.data.list;
          }
          this.tableData = this.processList(tableData);
          // 处理指标详情那几个特殊json 同 父组件-hiddenPage: false,
          if (params.special && params.special === 'orderCardList') {
            if (
              this.tableData[0] &&
              this.tableData[0].extInfo &&
              this.tableData[0].extInfo !== 'null' &&
              JSON.parse(this.tableData[0].extInfo).constructor === Array
            ) {
              let createTime = this.tableData[0].createTime;
              let monitoringStatusDesc = this.tableData[0].monitoringStatusDesc;
              this.tableData = JSON.parse(this.tableData[0].extInfo);
              this.tableData.forEach((obj) => {
                this.$set(obj, 'createTime', createTime);
                this.$set(obj, 'monitoringStatusDesc', monitoringStatusDesc);
                if (obj.images) {
                  obj.images.forEach((item, index) => {
                    obj.images[index] = `${
                      locationOrigin + '/site-monitor-service' + item
                    }`;
                  });
                }
              });
            } else if (this.tableData[0] && this.tableData[0].extInfo) {
              this.tableData[0].extInfo = JSON.parse(this.tableData[0].extInfo);
              // 图片处理
              if (
                this.tableData[0].manualImg &&
                this.tableData[0].manualImg !== ''
              ) {
                this.tableData[0].manualImg =
                  this.tableData[0].manualImg.split(',');
                this.tableData[0].manualImg.forEach((item1, index) => {
                  this.tableData[0].manualImg[index] = `${
                    locationOrigin + '/site-monitor-service' + item1
                  }`;
                });
              } else if (this.tableData[0].manualImg) {
                this.tableData[0].manualImg = [];
              }
              this.tableData = [this.tableData[0]];
            }
          }
          this.pageSize = 10;
          this.loading = false;
          // this.doLayout();
        });
      }
    },
    // 行单击事件
    rowClick(row, event, column) {
      this.$emit('row-click', row, event, column);
    },
    // 单元格单击事件
    cellClick(row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event);
    },
    // 单元格双击事件
    cellBbClick(row, column, cell, event) {
      this.$emit('cell-dblclick', row, column, cell, event);
    },
    // 行双击事件
    rowDblclick(row, enent) {
      this.$emit('row-dblclick', row, enent);
    },
    // 表头点击排序
    sortChange(column) {
      this.$emit('sort-change', column);
    },
    // 选择项发生变化时会触发该事件
    handleSelectionChange(selection) {
      this.multipleSelection = selection;
      this.$emit('selection-change', selection);
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      this.$emit('select', selection, row);
    },
    // 当用户手动勾选全选 Checkbox 时触发的事件
    selectAll(selection) {
      this.$emit('selectAll', selection);
    },
    // 修改页的大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.$emit('size-change', val);
      if (!this.enableExternalData) {
        this.reloadData(this.params);
      }
    },
    // 翻页的时候触发
    handleCurrentChange: function (val) {
      this.currentPage = val;
      this.$emit('current-change', val);
      if (!this.enableExternalData) {
        this.reloadData(this.params);
      }
    },
    // 行展开
    expandChange(row, expanded) {
      if (expanded.length > 0) {
        this.$emit('expand-change', row, expanded);
      }
    },
    $_buttonMethods(func, index, row) {
      this.$emit('click-btns', { func, index, row });
      this.$emit(func, { index, row });
    },
  },
};
</script>
<style lang="scss">
.com-data-table {
  .el-table {
    font-size: 14px;
  }

  .hiddenTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: normal;
  }
}

.com-data-table .el-radio__label {
  display: none !important;
}
// $borderColor: '#e1e8ee';
// .com-tab label.el-radio {
//   display: block;
//   height: 100%;
//   //transition: none!important;
// }
// .el-table .warning-row {
//   background: #ebf5ff;
// }
// .el-table th > .cell {
//   font-weight: 600;
// }
// .el-table .success-row {
//   background: #ebf5ff;
// }
// .el-table td,
// .el-table th {
//   padding: 10px 0;
// }
// /* .el-table--border,
// .el-table--group {
//     border: 1px solid #e1e8ee;
// } */
// .el-table thead,
// .el-table tbody {
//   color: #333333;
//   font-size: 16px;
// }
// .el-table--enable-row-hover .el-table__body tr:hover > td {
//   background-color: #ebf5ff !important;
// }

// /* 表格边框色 */
// .el-table--border:after,
// .el-table--group:after,
// .el-table:before {
//   background-color: #e1e8ee;
// }

// .el-table--border,
// .el-table--group {
//   border-color: #e1e8ee;
// }

// .el-table td,
// .el-table th.is-leaf {
//   border-bottom: 1px solid #e1e8ee;
// }

// .el-table--border th,
// .el-table--border th.gutter:last-of-type {
//   border-bottom: 1px solid #e1e8ee;
// }
// .com-tab .el-radio__label {
//   display: none !important;
// }
// .el-table--border td,
// .el-table--border th {
//   border-right: none;
// }
// //多级表头
// .multipleHeader {
//   &.el-table--border td,
//   &.el-table--border th {
//     border-right: 1px solid #e1e8ee;
//   }
// }
// .el-table .cell {
//   &.el-tooltip {
//     white-space: pre;
//   }
// }

// /* 分页 */
// .com-table-page {
//   margin-top: 10px;
//   .el-pagination button,
//   .el-pagination span:not([class*='suffix']),
//   .el-pager li {
//     line-height: 35px;
//     height: 35px;
//     font-size: 12px;
//     color: #666666;
//   }
// }
//
</style>
