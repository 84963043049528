<template>
  <div class="app-aside">
    <div style="display: flex">
      <img v-if="logo" :src="logo" class="sidebar-logo" />
      <div v-if="!$store.state.tagsView.isCollapse" class="sidebar-text" >监测平台</div>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        class="el-menu-vertical-demo menu-style"
        :collapse="$store.state.tagsView.isCollapse"
        :unique-opened="true"
        :default-active="$route.fullPath"
      >
        <template v-for="item in data">
          <sub-menu
            v-if="item.children.length"
            :label="2"
            :data="item"
            :key="item.fullPath"
          />
          <menu-item v-else :label="1" :data="item" :key="item.fullPath" />
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import subMenu from './sub-menu';
import menuItem from './menu-item';
import logoImg from '@/assets/logo/logo.png';
export default {
  name: 'appAside',
  components: {
    menuItem,
    subMenu,
  },
  props: {
    data: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      logo: logoImg,
    };
  },
  methods: {},
  mounted() {
    var that = this;
    // 左侧目录收起 暴露方法给外提供
    window['toggleSideBarTest'] = (event, name) => {
      //最终要调用的方法，调用并传入参数
      that.toggleSideBar(name);
    };
  },
};
</script>

<style lang="scss" >
.sidebar-logo {
  padding: 15px 0 15px 15px;
}
.sidebar-text{
  color: #fff;
  font-size: 16px;
  padding: 22px 0 15px 5px;
}
.app-aside {
  background: #102642;
  width: 100%;
  height: 100%;
  .scrollbar-wrapper {
    overflow-x: hidden;
  }
  .el-scrollbar {
    height: calc(100% - 60px);
    overflow: hidden;
    .el-menu {
      background: #102642;
      border-right: 1px solid #102642;
      &:not(.el-menu--collapse) {
        width: 200px;
      }
      .el-submenu {
        &.is-opened {
          background: #102642;
        }
        .el-submenu__title {
          display: flex;
          align-items: center;
          line-height: 0;
          color: #fff;
          &:hover {
            background: #3766F4;
          }
        }
        .el-menu-item {
          padding-right: 10px;
        }
      }
      .el-menu-item {
        display: flex;
        align-items: center;
        line-height: 0;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 48px;
        .el-tooltip {
          display: flex !important;
          align-items: center !important;
        }
        &.is-active,
        &:hover {
          background: #102642;
        }
      }
      .el-menu-item:hover, .el-menu-item:focus{
        background-color: #3766F4;
      }
    }
  }
}
</style>