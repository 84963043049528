<template>
  <el-table-column
    v-if="tableColum.show && tableColum.show === 'template'"
    v-bind="{ ...tableColum }"
    show-overflow-tooltip
  >
    <template slot-scope="scope">
      <slot :name="tableColum.prop" v-bind="scope" />
    </template>
    <template v-if="tableColum.children && tableColum.children.length > 0">
      <TableColumnItem
        v-for="(col, index) in tableColum.children"
        :key="col.key || col.prop || index"
        :column="col"
      />
    </template>
  </el-table-column>
  <el-table-column
    v-else-if="tableColum.type === 'selection'"
    v-bind="{ ...tableColum, ...columnWithSelection }"
  ></el-table-column>
  <el-table-column
    :sortable="tableColum.sortable"
    v-else
    show-overflow-tooltip
    v-bind="{ ...tableColum }"
  >
    <template v-if="tableColum.children && tableColum.children.length > 0">
      <TableColumnItem
        v-for="(col, index) in tableColum.children"
        :key="col.key || col.prop || index"
        :column="col"
      />
    </template>
  </el-table-column>
</template>

<script>
// 多级表头递归组件
export default {
  name: 'TableColumnItem',
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableColum() {
      return this.column;
    },
    columnWithSelection() {
      let _screen = window.innerWidth;
      if (_screen >= 1920) {
        return { width: '75', align: 'center' };
      } else {
        return { width: '55', align: 'center' };
      }
      //  else if (_screen >= 1600) {
      //   return '500';
      // } else if (_screen >= 1440) {
      //   return '500';
      // } else if (_screen >= 1366) {
      //   return '440';
      // }
    },
  },
};
</script>

<style scoped></style>
