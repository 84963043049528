<template>
  <el-submenu :index="data.menuId + ''" :key="data.menuId" class="menu-style">
    <template slot="title">
      <menu-title v-bind="data" />
    </template>
    <template v-for="menu in data.children">
      <sub-menu v-if="menu.children.length" :data="menu" :key="menu.menuId" />
      <menu-item
        v-else
        :index="menu.menuId + ''"
        :data="menu"
        :key="menu.menuId"
      />
    </template>
  </el-submenu>
</template>

<script>
import menuItem from './menu-item';
import MenuTitle from './menu-title.vue';
export default {
  name: 'SubMenu',
  components: {
    menuItem,
    MenuTitle,
  },
  props: {
    data: Object,
    label: Number,
  },
};
</script>

<style lang='scss'>
.el-submenu__title i {
  color: #fff !important;
}
</style>
