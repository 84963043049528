import Cookies from 'js-cookie';

const TokenKey = 'sso_code';

export function getToken(key) {
  let _key = key || TokenKey;
  return Cookies.get(_key);
}

export function setToken(token, key) {
  let _key = key || TokenKey;
  return Cookies.set(_key, token);
}

export function removeToken(key) {
  let _key = key || TokenKey;
  return Cookies.remove(_key);
}
