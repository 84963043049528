<template>
  <div class="search-wrap">
    <div class="search-box">
      <slot></slot>
      <div class="search-btn">
        <slot name="btn" v-if="isButton">
          <el-button type="primary" v-if="showBtn" size="small" @click="search">
            查 询
          </el-button>
          <el-button
            type="primary"
            size="small"
            plain
            v-if="isAdvanced"
            @click="advancedShow = !advancedShow"
          >
            {{ advancedShow ? '收起' : '高级查询' }}
          </el-button>
          <el-button size="small" v-if="isClear" @click="clearSearch">
            重 置
          </el-button>
          <!-- <el-button size="small" @click="search"> 刷新 </el-button> -->
          <el-button
            type="primary"
            size="small"
            v-if="more"
            @click="show = !show"
          >
            详情查询
          </el-button>
        </slot>
      </div>
    </div>
    <!-- 换行搜索 -->
    <!-- <div class="search-box">
      <slot name="line"> </slot>
    </div> -->
    <!--高级搜索-->
    <div class="advanced-box" v-if="isAdvanced" v-show="advancedShow">
      <div class="advanced-list">
        <slot name="advanced"></slot>
        <div class="search-btn">
          <slot name="btn">
            <!-- <el-button
              type="primary"
              size="small"
              @click="
                advancedShow = false;
                search();
              "
            >
              确定
            </el-button> -->
          </slot>
        </div>
      </div>
    </div>
    <div class="operate" v-if="this.$slots.opt">
      <slot name="opt"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import searchItem from './search-item';
Vue.component('searchItem', searchItem);
export default {
  props: {
    labelWidth: {
      type: String,
      default: '40px',
    },
    more: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    // 清空按钮
    isClear: {
      type: Boolean,
      default: true,
    },
    // 高级搜索按钮
    isAdvanced: {
      type: Boolean,
      default: false,
    },
    //是否搜索
    isButton: {
      type: Boolean,
      default: true,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    flex: String,
    //是否通过组件统一对参数进行重置
    //有的页面的重置不需要全部清除
    isResetByPublic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      advancedShow: false,
    };
  },
  mounted() {
    //
  },
  methods: {
    clearSearch() {
      this.$emit('clearSearch');
      if (this.isResetByPublic) {
        for (let key in this.model) {
          if (
            typeof this.model[key] === 'string' ||
            this.model[key] == null ||
            typeof this.model[key] === 'number'
          ) {
            if( key !='pageSource'){
              this.model[key] = '';
            }
          } else if (typeof this.model[key] === 'object') {
            if (this.model[key].length) {
              this.model[key] = [];
            } else {
              this.model[key] = {};
            }
          }
        }
        this.$emit('search');
      }else{
        this.$emit('search');
      }
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style scoped lang="scss">
.search-btn {
  display: flex;
  margin-left: 6px;
}
.search-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  .search-box,
  .advanced-list {
    flex: 1;
    display: flex;
    // flex-wrap: wrap;
    max-height: 250px;
    align-items: center;
    // overflow: auto;
    > * {
      align-items: center;
    }
  }
  .advanced-box {
    flex-direction: column;
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 100%;
    padding: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebeef5;
    border-radius: 4px;
    background: #ffffff;
  }
  .operate {
    float: right;
  }
}
</style>
