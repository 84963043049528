import Layout from '@/layout/index';
export default {
  path: '/project',
  name: 'project',
  component: Layout,
  redirect: '/project/contentWatchList',
  meta: {
    title: '监测概况',
  },
  children: [
    {
      path: 'contentWatchList',
      name: 'contentWatchList',
      component: () => import('@/views/surveyData/contentWatch'),
    },
    {
      path: '/orderCardList',
      name: 'orderCardList',
      component: () => import('@/views/surveyData/orderCardList'),
      meta: {
        title: '指标详情',
        isParent: true,
        keepAlive: true,
        goBack: true,
      },
    },
  ],
};
