<template>
  <el-container class="app-container">
    <el-container>
      <el-aside>
        <app-aside :data="menuList" />
      </el-aside>
      <el-main>
        <el-header><app-header /></el-header>
        <!-- <tags-view /> -->
        <app-main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppHeader from './components/app-header/app-header.vue';
import AppAside from './components/app-aside/index.vue';
import AppMain from './components/app-content/app-main.vue';

export default {
  name: 'Layout',
  components: {
    AppHeader,
    AppAside,
    AppMain,
  },
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    var userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    this.menuList = userInfo.menu;
  },
};
</script>

<style lang="scss" scoped>
.el-container.app-container {
  height: 100%;
  > .el-container {
    overflow: hidden;
    > .el-aside {
      width: auto !important;
    }
    > .el-main {
      padding: 0px;
      overflow: hidden;
      background: #edf0f5;
    }
  }
}
.el-header{
  padding: 0 8px;
  height: 46px !important;
}
</style>
