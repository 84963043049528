<script>
export default {
  name: 'MenuTitle',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    perms: {
      type: String,
      default: '',
    },
    menuName: {
      type: String,
      default: '',
    },
    meta: { type: Object, default: () => ({}) },
  },
  render(h, context) {
    const { icon, menuName, meta } = context.props;
    const vnodes = [];
    vnodes.push(<div class="shortLine" />);
    let newStr_icon = icon.replace(/#/g, '');
    if (newStr_icon) {
      vnodes.push(<svg-icon class="menuIcon" icon-class={icon} />);
    }
    const count = meta.count;
    let countNode;
    if (count > 0) {
      if (meta.markType === 'badge') {
        countNode = <span class="layui-badge">{count}</span>;
      } else {
        // brackets
        countNode = <span>({count})</span>;
      }
    }
    if (menuName) {
      vnodes.push(
        <span class="menuTxt" slot="title">
          {menuName}
          {countNode}
        </span>
      );
    }
    return vnodes;
  },
};
</script>
