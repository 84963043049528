<template>
  <div class="header">
    <div class="sys-msg">
      <hamburger
        :is-active="$store.state.tagsView.isCollapse"
        class="side-hamburger"
        @toggleClick="toggleSideBar"
      />
    </div>
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <!-- <div style="margin-left: 20px" @click="aaaCick">返回</div> -->
    <div class="user-right"><rightUser /></div>
  </div>
</template>

<script>
import rightUser from './user';
import Hamburger from '@/layout/components/hamburger';
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'AppHeader',
  components: {
    rightUser,
    Hamburger,
    Breadcrumb,
  },
  data() {
    return {};
  },

  mounted() {
    var that = this;
    // 左侧目录收起 暴露方法给外提供
    window['toggleSideBarTest'] = (event, name) => {
      //最终要调用的方法，调用并传入参数
      that.toggleSideBar(name);
    };
  },
  methods: {
    toggleSideBar() {
      this.$store.commit('tagsView/updateSharedValue');
    },
    // aaaCick() {
    //   this.$router.go(-1);
    // },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 5px;
  .sys-msg {
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    color: #49403e;
    opacity: 1;
    overflow: auto;
    padding-right: 10px;
    .sys-name {
      display: inline-block;
      background-size: 100% 100%;
    }
  }
  .user-right {
    width: 130px;
    height: 40px;
    line-height: 40px;
  }
}
</style>
