import request from '@/utils/require';
const baseUrl = '/site-monitor-service';

export default {
  // 获取监测概况
  getOverviewDetail(option) {
    return request.get(`${baseUrl}/overview/overviewDetail`, {
      params: option,
    });
  },
  // 获取监测概况
  getArtificialDetail(option) {
    return request.get(`${baseUrl}/orderstandarddata/treeList`, {
      params: option,
    });
  },
  // 获取监测指标list
  getOrderTreeList(option) {
    return request.get(`${baseUrl}/orderstandarddata/treeListSingle`, {
      params: option,
    });
  },
  // 获取监测订单号
  getInServiceOrderList() {
    return request.get(`${baseUrl}/order/getInServiceOrderList`);
  },
  // 获取指标详情-人工+机器（大部分）
  getArticlecheckList(option) {
    return request.get(`${baseUrl}/articlecheck/pageList`, {
      params: option,
    });
  },
  //人工+机器审核
  auditArticlecheck(option) {
    return request.post(`/site-monitor-service/articlecheck/audit`, option);
  },
  // 获取指标详情-机器（大部分）
  getOverviewcolumnList(option) {
    return request.get(`${baseUrl}/overviewcolumn/pageList`, {
      params: option,
    });
  },
  // 获取指标详情-机器（特殊：站点无法访问分页列表）
  getWebsiteList(option) {
    return request.get(`${baseUrl}/website/pageList`, {
      params: option,
    });
  },
  // 获取指标详情-机器（特殊：扣分-可用性1、2）
  getOverviewpagelinksList(option) {
    return request.get(`${baseUrl}/overviewpagelinks/pageList`, {
      params: option,
    });
  },
  // 获取指标详情-机器（特殊：扣分-页面标签1、2）
  getOverviewcontentList(option) {
    return request.get(`${baseUrl}/overviewcontent/pageList`, {
      params: option,
    });
  },
  // 获取指标详情-人工审核记录
  getOverviewmanualList(option) {
    return request.get(`${baseUrl}/overviewmanual/pageList`, {
      params: option,
    });
  },
  //人工审核
  createOverviewmanual(option) {
    return request.post(`/site-monitor-service/overviewmanual/create`, option);
  },
};
