export default {
  // 整改项目类型
  projectType: [
    {
      label: '一般审计项目',
      value: '0',
    },
    {
      label: '综合审计项目',
      value: '1',
    },
  ],
  // 审核状态
  status: [
    { label: '未审核', value: '1' },
    { label: '已审核', value: '2' },
    { label: '已审核', value: '3' },
    { label: '已审核', value: '4' },
    { label: '已审核', value: '5' },
  ],
  //   项目来源
  projectSource: [
    {
      label: '手工创建',
      value: '0',
    },
    {
      label: 'OA计划项目',
      value: '1',
    },
  ],
  // 项目状态
  projectStatus: [
    {
      label: '未启动',
      value: '0',
    },
    {
      label: '整改中',
      value: '1',
    },
  ],
  // 被审计单位是都自行公告审计结果
  opentag: [
    {
      label: '否',
      value: '0',
    },
    {
      label: '是',
      value: '1',
    },
  ],
  /**
   * 被审计单位
   *
   */
  //是否末级
  isLastLevel: [
    {
      label: '是',
      value: '1',
    },
    {
      label: '否',
      value: '0',
    },
  ],
  //是否本级单位
  isLevel: [
    {
      label: '是',
      value: '0',
    },
    {
      label: '否',
      value: '1',
    },
  ],
  //是否预算单位
  isBudget: [
    {
      label: '是',
      value: '1',
    },
    {
      label: '否',
      value: '0',
    },
  ],
  // 表单列间隔
  formDefaultGutter: 40,
};
