<template>
  <div class="form-btn-group" :style="{ padding: `0 ${padding}` }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormBtn',
  props: {
    padding: {
      type: String,
      default: '0px',
    },
  },
};
</script>
