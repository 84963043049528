import Layout from '@/layout/index';
export default {
  path: '/dataAcquisition',
  name: 'dataAcquisition',
  redirect: '/dataAcqu',
  component: Layout,
  meta: {
    title: '数据采集',
  },
  children: [
    {
      path: '/dataAcqu',
      name: 'dataAcqu',
      component: () => import('@/views/dataAcquisition/dataAcquisition.vue'),
      meta: {
        title: '数据采集',
        keepAlive: true,
        goBack: true,
      },
    },
  ],
};
