import request from '@/utils/require';
import { fd } from '@/utils/public';
const baseUrl = '/site-monitor-service';
export default {
  /************* 机构 *************/

  // 查询机构树
  treeData(option) {
    return request.get(`${baseUrl}/system/agency/treeData`);
  },
  // 查询机构树
  agencyTreeByOrder(option) {
    return request.get(`${baseUrl}/orderUser/agencyTreeByOrder?agencyCode=${option.agencyCode}&userCode=${option.userCode}`);
  },
  // 查询机构管理数据
  queryAgencyListData(option) {
    return request.post(`${baseUrl}/system/agency/list`, fd(option));
  },
  // 删除机构
  removeAgency(option) {
    return request.get(`${baseUrl}/system/agency/remove/${option.id}`);
  },
  // 修改机构
  editAgency(option) {
    return request.post(`${baseUrl}/system/agency/edit`, fd(option));
  },
  // 新增机构
  addAgency(option) {
    return request.post(`${baseUrl}/system/agency/add`, fd(option));
  },
  // 根据机构id查询详情
  getAgencyById(option) {
    return request.get(`${baseUrl}/system/agency/getAgencyById/${option.id}`);
  },
  // 机构下的订单
  findByAgency(option) {
    return request.post(
      `${baseUrl}/system/role/authUser/allocatedList`,
      fd(option)
    );
  },

  /************* 用户 *************/
  // 查询用户数据
  queryUserListData(option) {
    return request.post(`${baseUrl}/system/user/list`, fd(option));
  },
  // 新增用户
  addUser(option) {
    return request.post(`${baseUrl}/system/user/add`, fd(option));
  },
  // 删除用户
  removeUser(option) {
    return request.post(`${baseUrl}/system/user/remove`, fd(option));
  },
  // 修改用户
  editUser(option) {
    return request.post(`${baseUrl}/system/user/edit`, fd(option));
  },
  // 移出用户
  removeAgencyUser(option) {
    return request.get(`${baseUrl}/system/user/removeAgency/${option.ids}`);
  },
  // 用户分配订单列表查询
  systemFindAgencyUser(option) {
    return request.post(
      `${baseUrl}/orderUser/systemFindAgencyUser?pageNum=${option.pageNum}&pageSize=${option.pageSize}`,
      option
    );
  },
  // 机构订单分配列表查询
  systemFindAgencyOrg(option) {
    return request.post(`${baseUrl}/orderUser/systemFind`, fd(option));
  },
  // 系统管理-保存机构订单分配
  systemSaveAgencyOrder(option) {
    return request.post(
      `${baseUrl}/orderUser/systemSaveAgencyOrder?agencyCode=${option.agencyCode}&orderCode=${option.orderCode}`
    );
  },
  // 系统管理-批量保存机构订单分配
  stemSaveAgencyOrderList(option) {
    return request.post(
      `${baseUrl}/orderUser/systemSaveAgencyOrderList`,
      option
    );
  },
  // 系统管理-批量改新增或者删除用户订单信息
  addOrRemovoUserAll(option) {
    return request.post(`${baseUrl}/orderUser/addOrRemovoUserAll`, option);
  },
  // 系统管理-是否能直接取消机构订单分配
  systemCancelSaveAgencyOrder(option) {
    return request.post(
      `${baseUrl}/orderUser/systemCancelSaveAgencyOrder?agencyCode=${option.agencyCode}&orderCode=${option.orderCode}`
    );
  },
  // 系统管理-取消机构订单分配
  systemRemoveAgencyOrder(option) {
    return request.post(
      `${baseUrl}/orderUser/systemRemoveAgencyOrder?agencyCode=${option.agencyCode}&orderCode=${option.orderCode}`
    );
  },
  // 系统管理-批量取消机构订单分配
  systemRemoveAgencyOrderList(option) {
    return request.post(
      `${baseUrl}/orderUser/systemRemoveAgencyOrderList`,
      option
    );
  },
  /************* 角色 *************/
  // 查询角色数据
  queryRoleListData(option) {
    return request.post(`${baseUrl}/system/role/list`, fd(option));
  },
  // 新增角色
  addRole(option) {
    return request.post(`${baseUrl}/system/role/add`, fd(option));
  },
  // 删除角色
  removeRole(option) {
    return request.post(`${baseUrl}/system/role/remove`, fd(option));
  },
  // 编辑角色
  editRole(option) {
    return request.post(`${baseUrl}/system/role/edit`, fd(option));
  },
  // 导出角色表
  exportRole(option) {
    return request.post(`${baseUrl}/system/role/export`, fd(option));
  },
  // 修改角色菜单信息
  updateRoleMenu(option) {
    return request.post(`${baseUrl}/system/role/updateRoleMenu`, fd(option));
  },
  // 角色成员查询
  allocatedList(option) {
    return request.post(
      `${baseUrl}/system/role/authUser/allocatedList`,
      fd(option)
    );
  },
  //批量移除角色下的成员
  cancelAllRoleUser(option) {
    return request.post(
      `${baseUrl}/system/role/authUser/cancelAll`,
      fd(option)
    );
  },

  /************* 权限管理配置 *************/
  // 查询权限配置数据
  queryAuthConfigListData(option) {
    return request.post(`${baseUrl}/system/authConfig/list`, fd(option));
  },
  // 删除权限配置数据
  deleteAuthConfigData(option) {
    return request.post(`${baseUrl}/system/authConfig/delete`, fd(option));
  },
  // 查询权限归属模块和角色数据
  queryAuthConfigModuleAndRoleList(option) {
    return request.post(
      `${baseUrl}/system/authConfig/queryAuthConfigModuleAndRoleList`,
      fd(option)
    );
  },
  // 新增权限配置
  addAuthConfig(option) {
    return request.post(
      `${baseUrl}/system/authConfig/addAuthConfig`,
      fd(option)
    );
  },
  // 修改权限配置管理状态
  updateAuthConfigStatusByAuthId(option) {
    return request.post(
      `${baseUrl}/system/authConfig/updateAuthConfigStatusByAuthId`,
      fd(option)
    );
  },
  // 修改权限配置
  updateAuthConfigData(option) {
    return request.post(
      `${baseUrl}/system/authConfig/updateAuthConfigData`,
      fd(option)
    );
  },

  /************* 菜单 *************/
  // 查询菜单数据
  queryMenuListData(option) {
    return request.post(`${baseUrl}/system/menu/list`, fd(option));
  },
  // 新增菜单
  addMenu(option) {
    return request.post(`${baseUrl}/system/menu/add`, fd(option));
  },
  // 删除菜单
  removeMenu(option) {
    return request.delete(`${baseUrl}/system/menu/remove/${option.ids}`);
  },
  // 编辑菜单
  editMenu(option) {
    return request.post(`${baseUrl}/system/menu/edit`, fd(option));
  },
  // 导出菜单表
  // 加载所有菜单列表树
  menuTreeData(option) {
    return request.get(`${baseUrl}/system/menu/menuTreeData`);
  },
  // 加载角色菜单列表树
  roleMenuTreeData(option) {
    return request.get(`${baseUrl}/system/menu/roleMenuTreeData`);
  },

  //数据字典
  //查询数据列表（父级）
  // queryCategoryList(option) {
  //   return request.post(`${baseUrl}/system/dictionary/findByPiD/${option.pid}`);
  // },
  queryCategoryList(option) {
    return request.post(`${baseUrl}/system/dictionary/findByPid`, fd(option));
  },
  //查询右侧数据列表(子)
  // queryCategoryListC(option) {
  //   return request.post(
  //     `${baseUrl}/system/dictionary/findByCategoryName`,
  //     fd(option)
  //   );
  // },
  //根据id查询字典分级显示所有 树结构
  queryNextategoryList(option) {
    return request.get(
      `${baseUrl}/system/dictionary/findLowerLevelByPid/${option}`
    );
  },
  //新增字典(所有级别的新增都走这个接口)
  addDict(option) {
    return request.post(`${baseUrl}/system/dictionary/add`, fd(option));
  },

  //编辑字典
  updateDict(option) {
    return request.post(`${baseUrl}/system/dictionary/update`, fd(option));
  },
  // 删除用户
  removeDict(option) {
    return request.delete(
      `${baseUrl}/system/dictionary/removeById/${option.id}`
    );
  },

  queryModelConfigList(option) {
    return request.post(
      `${baseUrl}/sys_model_config/queryModelConfigList`,
      fd(option)
    );
  },

  saveModelConfig(option) {
    return request.post(`${baseUrl}/sys_model_config/saveModelConfig`, option);
  },

  deleteModelConfigByIdList(option) {
    return request.post(
      `${baseUrl}/sys_model_config/deleteModelConfigByIdList`,
      fd(option)
    );
  },

  deleteLogoImgFile(option) {
    return request.post(
      `${baseUrl}/sys_model_config/deleteLogoImgFile`,
      option
    );
  },

  deleteLogoImgFileList(option) {
    return request.post(
      `${baseUrl}/sys_model_config/deleteLogoImgFileList`,
      option
    );
  },

  loadImg(option) {
    return request.post(`${baseUrl}/sys_model_config/loadImg`, fd(option));
  },

  queryModelConfigByAgencyIdAndId(option) {
    return request.post(
      `${baseUrl}/sys_model_config/queryModelConfigByAgencyIdAndId`,
      fd(option)
    );
  },
};
