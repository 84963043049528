// eslint-disable-next-line no-extend-native

/**
 * 函数节流 - gapTime ms 内只执行一次
 * @param {function} fn 函数本身
 * @param {number} gapTime 时间
 */
export const throttle = function (fn, gapTime) {
  /*eslint-disable*/
  if (gapTime == null || gapTime == undefined) {
    gapTime = 2000;
  }

  let _lastTime = null;

  // 返回新的函数
  return function () {
    const _nowTime = +new Date();
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments); // 将this和参数传给原函数
      _lastTime = _nowTime;
    }
  }
}

/**
 *
 * @param {Array} sumSelects - 求和的选择器列表
 * @param {Array} subTractSelects - 求差的选择器列表
 * @param {number} margin - 多减的数量，如不多减可能刀子底部显示不完整
 */
export function calcDomHeight(sumSelects = [], subTractSelects = [], margin = 15) {
  const defaultClient = { clientHeight: 0 }
  let sum = sumSelects.reduce(
    (total, current) => total + (document.querySelector(current) || defaultClient).clientHeight,
    0
  )
  let sub = subTractSelects.reduce(
    (total, current) => total + (document.querySelector(current) || defaultClient).clientHeight,
    0
  )
  return sum - sub > 0 ? sum - sub - margin : 0
}

/**
 * 导出/批量下载  文档流转文件
 * @param {Obj} res
 */
export function fileDocumentFn (res) {
  // 获取到文件名
  const fileName = decodeURI(res.headers.filename)
  const blob = new Blob([res.data])
  const downloadElement = document.createElement('a')
  // 创建下载的链接
  const href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  // 下载后文件名
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  // 点击下载
  downloadElement.click()
  // 下载完成移除元素
  document.body.removeChild(downloadElement)
  // 释放掉blob对象
  window.URL.revokeObjectURL(href)
}

/**
 * Object转换FormData
 * 内容检查 1.2版本 使用FormData形式传参数
 *         1.0版本 使用Object(requestBody)形式传参
 */
export function fd (params) {
  const formData = new FormData();
  for(let key in params) {
    formData.append(key, params[key])
  }
  return formData
}
