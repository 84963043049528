<template>
  <div class="region-select" :class="{ isView: isView }">
    <div v-show="isView">{{ checkedText }}</div>
    <el-cascader
      v-show="!isView"
      ref="refRegion"
      :value="value"
      :options="regionList"
      :props="regProp"
      v-bind="iptConf"
      @change="handleChange"
      :placeholder="placeholderText"
      :disabled="disabled"
    ></el-cascader>
  </div>
</template>

<script>
import area from '@/utils/area';
// import classifyType from '@pkg/utils/AssetsClassificationCache';
export default {
  name: 'RegionSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    isView: { type: Boolean, default: false },
    /** 取值为： id/text */
    valueField: { type: String, default: 'id' },
    value: { type: Array, default: () => [] },
    dataSource: { type: String, default: 'area' },
    placeholderText: { type: String, default: '' },
    checkStrictly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      iptConf: {
        clearable: true,
        filterable: true,
      },
      regProp: {
        expandTrigger: 'hover',
        children: 'child',
        value: this.valueField,
        label: 'text',
        checkStrictly: this.checkStrictly, //单选选择任意一级选项
      },
      regionList: [],

      checkedText: '',
      checkedTxts: [],
    };
  },
  watch: {
    value: {
      handler: function (val) {
        setTimeout(() => {
          this.getText();
        }, 60);
        // 优化处理 点击地图回显数据后 再次搜索门牌楼号房号无数据情况
        this.$nextTick(() => {
          this.getCheckedDatas();
        });
      },
      deep:true
    },
  },
  mounted: function () {
    // var type = this.dataSource === 'classifyType' ? classifyType : area;
    var type = area;
    this.regionList = Object.freeze(type);
    this.$nextTick(() => {
      this.getText();
    });
  },
  methods: {
    getText: function () {
      const refIns = this.$refs['refRegion'];
      if (refIns) {
        this.checkedText = refIns.presentText;
      }
    },
    handleChange: function (val) {
      this.$emit('change', val, this.getCheckedDatas());
    },
    getCheckedDatas: function () {
      const datas = [];
      const checkedTxts = [];
      const selNode = this.$refs['refRegion'].getCheckedNodes()[0];
      if (!selNode) {
        return datas;
      }
      this.$refs['refRegion'].getCheckedNodes()[0].pathNodes.map((item) => {
        checkedTxts.push(item.data.text);
        datas.push({ id: item.data.id, text: item.data.text });
      });

      this.checkedTxts = checkedTxts;
      return datas;
    },
  },
};
</script>

<style lang="scss">
.region-select {
  // width: 100%;
  .el-cascader {
    width: 100%;
  }
}
</style>
