import request from '@/utils/require';

export default {
  // 获取用户信息
  getUser() {
    return request.get(`/site-monitor-service/getUser`);
  },
  // 退出
  logoutUser() {
    return request.get(`/site-monitor-service/logout`);
  },
};
