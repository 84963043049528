import api from '@/api/module/user';
import { removeToken, getToken } from '@/utils/auth';
import Cookies from 'js-cookie'
const state = {
  token: '',
  userName: '',
  userLoginName: '',
  userID: '',
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER: (state, user) => {
    Object.assign(state, user);
  },
  LOGOUT: (state) => {
    state = {};
    state.token = '';
  },
};

const actions = {
  GetUserInfo({ commit }) {
    return api.getUser().then((res) => {
      Cookies.set('Token', res.data.token) //登录成功后将token存储在cookie之中
      commit('SET_TOKEN', res.data.token)
      commit('SET_USER', res.data);
    });
    // const _timer = setInterval(() => {
    //   if (getToken('sso_token')) {
    //     clearInterval(_timer);
        
    //   }
    // });
  },
  Logout({ commit }) {
    return new Promise(function (resolve, reject) {
      api
        .logoutUser()
        .then((res) => {
          commit('LOGOUT');
          removeToken('Token');
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
