<template>
  <router-view />
</template>

<script>
export default {
  name: 'APP',
  beforeCreate() {
    // this.$store.dispatch('user/GetUserInfo');
  },
};
</script>

<style lang="scss"></style>
