import Layout from '@/layout/index';
export default {
  path: '/dataCollector',
  name: 'dataCollector',
  redirect: '/dataColle',
  component: Layout,
  meta: {
    title: '采集人员',
  },
  children: [
    {
      path: '/dataColle',
      name: 'dataColle',
      component: () => import('@/views/dataCollector/dataCollector.vue'),
      meta: {
        title: '采集任务管理',
        keepAlive: true,
        goBack: true,
      },
    },
    {
      path: '/templateManage',
      name: 'templateManage',
      component: () => import('@/views/dataCollector/templateManage.vue'),
      meta: {
        title: '模版管理',
        keepAlive: true,
        goBack: true,
      },
    },
  ],
};
