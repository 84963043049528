/**
 * 简单数据转树形数据
 *
 * */
export function transformTree(
  list,
  { id = 'id', pId = 'pId', children = 'children', label } = {}
) {
  const tree = [];
  list.some((item) => {
    if (label) {
      item.label = item[label];
    }
    if (!item[pId] || item.pid < 0) {
      const node = queryChildren(item);
      tree.push(node);
    }
  });
  function queryChildren(parent) {
    const _children = [];
    list.some((item) => {
      if (item[pId] === parent[id]) {
        const node = queryChildren(item);
        _children.push(node);
      }
    });
    if (_children.length) {
      parent[children] = _children;
    }
    return parent;
  }
  return tree;
}
/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children',
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}
export function getListData(data, config) {
  let id = config.id || 'code';
  let pId = config.pId || 'parentCode';
  let children = config.children || 'children';
  let idMap = {};
  let jsonTree = [];
  data.forEach(function (v) {
    idMap[v[id]] = v;
  });
  data.forEach(function (v) {
    let parent = idMap[v[pId]];
    if (parent) {
      !parent[children] && (parent[children] = []);
      parent[children].push(v);
    } else {
      jsonTree.push(v);
    }
  });
  return jsonTree;
}
export function getParam(name) {
  let reg = new RegExp('(^|&?)' + name + '=([^&#]*)(&|$|#)'); // 构造一个含有目标参数的正则表达式对象
  let r = window.location.href.match(reg); // 匹配目标参数
  if (r != null) return decodeURI(r[2]);
  return null; // 返回参数值
}

/**
 * 复制单行内容到粘贴板
 * @param {string} content 需要复制的内容
 * @param {function} [sucCb] 成功回调
 */
export function copyToClip(content, sucCb) {
  var aux = document.createElement('input');
  aux.setAttribute('value', content);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);

  typeof sucCb === 'function' && sucCb();
}
// 判断网页打开是否为微信QQ
export function isWeixin () {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //微信中打开网页
    return true;
  } else if (ua.match(/QQ/i) == 'qq') {
    //qq里打开网页
    return true;
  } else {
    return false;
  }
}

