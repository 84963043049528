import axios from 'axios';
import store from '@/store/index'; // 引入vuex
import { Message, MessageBox } from 'element-ui';
import router from '@/router/index';
let isShowLoginALert = false;
// 创建axios实例
const service = axios.create({
  // 开发环境
  // baseURL: '/baseApi_dev',
  // 测试环境
  // baseURL: '/baseApi_test',
  // 请求超时时间
  timeout: 180000,
});
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    let data = response.data;
    if (data instanceof Blob) {
      return response;
    }
    // 成功
    if (data.code == 0) {
      return response.data;
    }
    // 成功
    if (data.code == 200) {
      return response.data;
    }
    //错误
    if (data.code == 500) {
      Message.closeAll()
      Message.error({
        message:`${ data.msg || '服务器错误'}`,
        duration: 1500,
      });
      return response.data;
    }
    // 警告
    if (data.code == 301) {
      Message.warning(data.msg);
      return response.data;
    }
    // 登陆过期
    if (data.code == 'B0006') {
      if (!isShowLoginALert) {
        isShowLoginALert = true;
        MessageBox.alert('登录过期，请重新登录!', {
          title: '提示',
          confirmButtonText: '确定',
          showClose: false,
          type: 'warning',
        }).then(() => {
          isShowLoginALert = false;
          router.push({
            name: 'login',
            params: { loginExpired: true },
          });
          // 退出成功后关闭所有tag
          store.dispatch('tagsView/delAllViews');
        });
      }
    } else {
      //其他错误
      let errmsg = data.msg ? data.msg : data.message
      Message.error(errmsg);
      return Promise.reject('error');
    }
  },
  //错误处理
  (error) => {
    //请求超时的处理
    let originalRequest = error.config;
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1 &&
      !originalRequest._retry
    ) {
      Message.error('请求超时');
      return;
    } else {
      //其他错误
      Message.error('服务器错误或请求超时');
    }
    return Promise.reject(error);
  }
);

export default service;
