

import loginIn from './module/loginIn';
import personalCenter from './module/personalCenter';
import sysManage from './module/sysManage';
import operationOrder from './module/operationOrder';
import dataAcquisition from './module/dataAcquisition';
import surveyData from './module/surveyData';

export default {
  loginIn,
  personalCenter,
  sysManage,
  operationOrder,
  surveyData,
  dataAcquisition,
};
