<template>
  <div class="search-item" v-show="show" :style="{ width: width }">
    <label
      v-if="label"
      class="search-label"
      :style="{ width: labelWidth2Rem }"
      :title="label"
    >
      {{ label }}
    </label>
    <div class="search-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchItem',
  props: {
    label: String,
    default: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelWidth2Rem() {
      return parseInt(this.$parent.labelWidth) / 16 + 'rem';
    },
    show() {
      return true;
      // if (this.default || (!this.default && this.$parent.show)) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    width() {
      let _flex = '';
      if (this.$parent.flex) {
        _flex = this.$parent.flex;
      } else {
        _flex = '3';
      }
      let _width = '';
      switch (_flex) {
        case '1':
          _width = '80%';
          break;
        case '2':
          _width = '45%';
          break;
        case '3':
          _width = '30%';
          break;
        case '4':
          _width = '25%';
          break;
        case '5':
          _width = '20%';
          break;
        case '5.5':
        _width = '19%';
        break;
        case '6':
          _width = '15%';
          break;
        case '7':
          _width = '14%';
          break;
        case '8':
          _width = '12%';
          break;
      }
      return _width;
    },
  },
  watch: {
    show(val) {
      if (!val) {
        // this.$children[0]?.clear();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-item {
    padding: 3px;
  .search-label {
    white-space: initial;
    display: inline-block;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
  .search-content {
    flex: 1;
  }
}
</style>
