import Layout from '@/layout/index';
export default {
  path: '/sysManage',
  name: 'sysManage',
  // redirect: '/sysManage/institutionalUser',
  component: Layout,
  meta: {
    title: '系统管理',
  },
  children: [
    {
      path: 'institutionalUser',
      name: 'institutionalUser',
      component: () => import('@/views/sysManage/institutionalUser'),
      meta: {
        title: '机构用户管理',
        keepAlive: true, // 需要被缓存
      },
    },
    {
      path: 'roleManage',
      name: 'roleManage',
      component: () => import('@/views/sysManage/roleManage'),
      meta: {
        title: '角色管理',
        keepAlive: true,
      },
    },
    {
      path: 'roleMember',
      name: 'roleMember',
      component: () => import('@/views/sysManage/roleMember'),
      meta: {
        title: '角色成员',
        keepAlive: true,
      },
    },
    {
      path: 'menuManage',
      name: 'menuManage',
      component: () => import('@/views/sysManage/menuManage'),
      meta: {
        title: '菜单管理',
        keepAlive: true,
      },
    },
    {
      path: 'institutionalMember',
      name: 'institutionalMember',
      component: () => import('@/views/sysManage/institutionalMember'),
      meta: {
        title: '机构订单',
        keepAlive: true,
      },
    },
    {
      path: 'userMember',
      name: 'userMember',
      component: () => import('@/views/sysManage/userMember'),
      meta: {
        title: '订单配置',
        keepAlive: true,
      },
    },
    {
      path: 'dictManage',
      name: 'dictManage',
      component: () => import('@/views/sysManage/dictManage'),
      meta: {
        title: '字典管理',
        keepAlive: true,
      },
    },
    {
      path: 'dictNextManage',
      name: 'dictNextManage',
      component: () =>
        import('@/views/sysManage/component/dictComponent/dictNextManage'),
      meta: {
        title: '配置字典',
        keepAlive: true,
      },
    },
  ],
};
