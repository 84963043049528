import Layout from '@/layout/index';
export default {
  path: '/webManage',
  name: 'webManage',
  component: Layout,
  redirect: '/website',
  meta: {
    title: '网站管理',
    keepAlive: true,
    goBack: true,
  },
  children: [
    {
      path: '/website',
      name: 'website',
      component: () => import('@/views/orderManage/webManage'),
    },
    {
      path: '/webColumn',
      name: 'webColumn',
      component: () => import('@/views/orderManage/components/webColumn'),
      meta: {
        title: '栏目管理',
        keepAlive: true,
        goBack: true,
      },
    },
    {
      path: '/webSetting',
      name: 'webSetting',
      component: () => import('@/views/orderManage/components/webSetting'),
      meta: {
        title: '网站设置',
        keepAlive: true,
        goBack: true,
      },
    },
  ],
};
