<template>
  <div class="content-wrap">
    <section class="app-main">
      <!-- <transition name="fade-transform" mode="out-in"> -->
      <!-- 需要缓存的视图组件 -->
      <keep-alive :include="cacheViews" :max="50">
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
      </keep-alive>
      <!-- </transition> -->
      <!-- 不需要缓存的视图组件 -->
      <router-view
        v-if="!$route.meta.keepAlive"
        :key="$route.fullPath"
      ></router-view>
    </section>
  </div>
</template>

<script>
export default {
  name: 'appMain',
  data() {
    return {
      include: [],
    };
  },
  computed: {
    cacheViews() {
      const d = this.$store.state.tagsView.visitedViews.map((item) => {
        return item.name;
      });
      return d;
    },
    key() {
      return this.$route.fullPath;
    },
  },
  watch: {
    $route(to) {
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.app-main {
  flex: 1;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 50px;
}
</style>
