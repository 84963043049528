import request from '@/utils/require';
import { fd } from '@/utils/public';
const baseUrl = '/site-monitor-service';


export default {
  queryCurrentUserInfo() {
    return request.post(`${baseUrl}/personalCenter/queryCurrentUserInfo`);
  },

  saveUserInfo(option) {
    return request.post(`${baseUrl}/personalCenter/saveUserInfo`, option);
  },

  sendPhoneCode(option) {
    return request.post(`${baseUrl}/personalCenter/sendPhoneCode`, fd(option));
  },

  sendEMailCode(option) {
    return request.post(`${baseUrl}/personalCenter/sendEMailCode`, fd(option));
  },

  updateUserPassword(option) {
    return request.post(`${baseUrl}/personalCenter/updateUserPassword`, option);
  },
  sendFeedBack(option) {
    return request.post(`${baseUrl}/feedBack/sendFeedBack`, fd(option));
  },

};

